.switch {
    position: relative;
    display: inline-block;
    width: 71.91px;
    height: 34px;
    transform: scale(0.7);
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-color: #66CC33;
}

.switch input:disabled+.slider {
    opacity: 0.8;
    cursor: not-allowed !important;
}

.switch input:disabled+.slider:before {
    opacity: 0.8;
    cursor: not-allowed !important;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #66CC33;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch-body {
    display: inline-block;
}

.switch-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center
}

.switch-row {
    display: flex;
    flex-direction: row;
}

.switch-column {
    display: flex;
    flex-direction: column;
}

.switch-text-top {
    align-self: center
}

.switch-text-left,
.switch-text-right {
    display: flex;
    align-items: center
}

.input-default {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}

.MuiOutlinedInput-input{
    padding: .375rem .75rem !important;
}
