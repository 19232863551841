.container-papeis {
    display: flex;
    flex-direction: column;
    height: 50rem;
    padding: 0% !important;
    float: left;
    width: 98%;
}

.container-papeis section {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 6.25rem;
    border: 1px solid #CCCFDE;
    overflow-y: scroll
}

.container-papeis section > div {
    border-right: 1px solid #CCCFDE;
}
.atributos{
    align-items: center;
    flex: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-bottom: 1px solid #CCCFDE;
    height: 70px;
}
.atributosActive{
    background: #C2F291;
    transition: 0.5s;
}

.atributos p {
    align-self: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 18px ;
    font-size: 13px;
    font-weight: 700;
    color: var(--color-preto)
}

.switch {
    margin-top: 15px;
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 7px
}

.cardBody {
    background: var(--color-quase-branco);
    min-height: 166px;
    width: 750px; 
    margin-left: 17px; 
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid #CCCFDE;
}