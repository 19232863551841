:root {
  --color-preto: #4f585a;
  --color-cinza-medio: #81888a;
  --color-cinza-claro: #c4c7ca;
  --color-quase-branco: #f5f6fa;
  --color-branco: #ffffff;
  --color-verde-escuro: #1f7962;
  --color-verde: #25957a;
  --color-verde-claro: #66cc33;
  --color-laranja: #ff6635;
  --color-azul: #10a9ff;
  --color-roxo: rgba(102, 45, 145, 1);
  --color-gradiente-verde: linear-gradient(
    225deg,
    #2db695 9.96%,
    #1f7962 91.53%
  );
  --color-gradiente-roxo: linear-gradient(360deg, #7559ff 0%, #8a7fff 100%);

  --color-gradiente-laranja: linear-gradient(
    225deg,
    #ff8c4d 9.96%,
    #ff6635 91.53%
  );
  --color-gradiente-amarelo: linear-gradient(
    188.88deg,
    #ffbd34 6.76%,
    #ff9635 113.07%
  );
  --color-gradiente-verde-claro: linear-gradient(
    225deg,
    #66cc33 9.96%,
    #52a529 91.53%
  );
  --color-gradiente-azul: linear-gradient(360deg, #00c2d4 0%, #49d8e5 100%);
  overflow-x: hidden;
}

* {
  font: 400 14px Lato;
}

.background-login {
  background: url("./assets/images/Login background.png") no-repeat bottom
    center scroll;
  background-size: cover;
}

.view-background {
  background-color: var(--color-quase-branco);
}

.container-full {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-button {
  background: var(--color-roxo) !important;
  width: 200px;
}

.default-color-login {
  color: var(--color-roxo) !important;
}

.purpleButton {
  background: var(--color-roxo) !important;
  border-radius: 7px !important;
  border: 0 !important;
}
.clearButton {
  width: 150px;
  margin-right: 10px;
  background: var(--color-branco) !important;
  border-color: var(--color-laranja) !important;
  color: var(--color-laranja) !important;
  border-radius: 7px !important;
}

.greyButton {
  background: var(--color-cinza-medio);
  border-radius: 7px !important;
  border: 0 !important;
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
  

}

table td,
.table th {
  padding: 8px;
  /* border-bottom: 1px solid #cbd0d1; */
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #81888a;
  overflow-x: auto;
  text-align: left;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 1px;
  text-align: left;
  background: #f5f6fa;
  color: #4f585a;
  font-weight: 700;
  font-size: 14px;
  overflow-x: auto;
  
}


.page-link {
  color: #81888a !important;
  font-size: 14px;
  float: left;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 0 4px;
  border-radius: 10px;
  cursor: pointer !important;
}

.page-active:active {
  background-color: var(--color-verde-claro);
  color: white;
  border: 1px solid #4caf50;
}

.page-link:hover:not(.active) {
  text-decoration: none;
  background: var(--color-verde-escuro);
  color: var(--color-branco) !important;
  cursor: pointer !important;
}

.pagination {
  margin-right: 7px;
  margin-top: 8px;
}

.pagination > .active > a {
  background-color: var(--color-verde-escuro);
  border-color: --color-cinza-claro !important;
  color: var(--color-branco) !important;
}

.pagination > li > a {
  border: 1px solid var(--color-cinza-medio) !important;
  padding: 6px 12px;
  border-radius: 10px !important;
  outline: none;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  color: var(--color-cinza-medio) !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--color-verde-escuro);
  border-color: var(--color-cinza-medio) !important;
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: var(--color-branco);
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.pageSize {
  color: var(--color-cinza-medio) !important;
  width: 103px !important;
  height: 33px !important;
  border-radius: 10px !important;
  margin-right: 15px;
  border-color: var(--color-cinza-medio) !important;
}

.btn-icon-sidebar {
  margin-left: 2rem;
  font-size: 2rem;
  margin-top: 30px;
  color: Grey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.btn-icon-sidebar:hover {
  color: var(--color-roxo);
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  display: none
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid #048453;
  width: 15px;
  height: 15px;
  top: 0;
  left: 0;
  background-color: #f8f8f8;
  border-radius: 2px;
}
input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  left: 0;
  background-color: white;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid #048453;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invalid-select {
  border-color: red !important;
}

.margin-row {
  margin: 15px 0 0 5px !important;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: greenyellow;
  border-radius: 50%;
  display: inline-block;
}

@media print {
  html,
  body {
    margin: 0;
    padding: 0;
    border: 0;
  }
  #printable {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
  }
  #printable ~ * {
    display: none;
  }
}

input:disabled {
  cursor: not-allowed !important;
}
.search-form {
  margin: 30px 15px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.swal2-icon {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 2.5em auto 0.6em;
    border: 0.25em solid rgba(0,0,0,0);
    border-radius: 50%;
    border-color: #000;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    user-select: none;
}
.contact-container {
  padding: 20px;
}

.contact-card {
  background-color: var(--color-quase-branco);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.add-icon {
  font-size: 1.5rem;
  color: green;
  cursor: pointer;
  margin-right: 8px;
}
